import { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom"; 
import Page from "./components/Page";
import VersionDisplay from "./components/VersionDisplay";
import FormStepProgress from "./components/FormStepProgress";
import Dialog from "./components/Dialog";
import api from './api/api';

import {
    StyledHeader,
    StyledLogoContainer,
    StyledContent,
    StyledTitleContainer,
    StyledEncryptionReminder,
    StyledFormHeaderContainer,
    StyledForm,
    StyledEventSelect,
    StyledCompanyInfo,
    StyledMateImage,
    StyledFooter,
    StyledDialogContent,
    StyledButton,
} from "./styles/styles";

interface EventProps {
    [key: string]: any;
}

interface DateOption {
    date : string,
    isAvailable : boolean
}

function formatDate(date: string) {

    const dateOptions = { era: "long" as const };
    const dayOptions = { weekday: "long" as const };

    const dayOfWeek = new Intl.DateTimeFormat("ja-JP", dayOptions)
        .format(new Date(date))
        .substring(0, 1);

    const dateFormatted = new Intl.DateTimeFormat("ja-JP", dateOptions)
        .format(new Date(date))
        .substring(2);

    return `${dateFormatted} (${dayOfWeek})`;
}

function formatTime(date: string) {

    const time = date.split(":");
    return `${time[0]}:${time[1]}`;
}

const steps = ["希望日時入力", "基本情報入力", "ご確認"];

function App() {

    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [isSticky, setIsSticky] = useState(false);

    const [events, setEvents] = useState<[]>();
    const [dates, setDates] = useState<DateOption[]>([]);
    const [date, setDate] = useState("");
    const [currentStep, setCurrentStep] = useState(0);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const [branchAvailability, setBranchAvailability] = useState(new Map());

    const [branchId, setBranchId] = useState("");
    const [branches, setBranches] = useState<any>([]);

    const [event, setEvent] = useState("");
    const [fullEvent, setFullEvent] = useState<EventProps>({});

    const [comment, setComment] = useState("");
    const [newsletter, setNewsletter] = useState(true);
    const [privacy, setPrivacy] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [message, setMessage] = useState("");

    const memberHash = searchParams.get('mh') ?? "";

    const disableUnavailableBranches = async () => {

        if (!branchAvailability || typeof branchAvailability.get(date) === 'undefined') {
            return;
        }

        const branchesCopy = [...branches];
        const availabilityForDate = branchAvailability.get(date);

        for (let branch of branchesCopy) {
            if (availabilityForDate.includes(branch.id)) {
                branch.available = true;
            } else {
                branch.available = false;
            }
        }
        setBranches(branchesCopy);
    }

    useEffect(() => {

        // if memberHash param is present, re-route to set_password page
        // note that routing must be done in this way (instead of linking user directly to
        // set_password page) because S3 only hosts "static pages" which cannot handle the
        // URL-based routing that a server normally does
        if (memberHash) {
            navigate({
                pathname: '/set_password',
                search: createSearchParams({
                    mh : memberHash
                }).toString(),
            });
            return;
        }

        let weekOfDates = [];

        for (let i = 0; i <= 7; i++) {
            let date = new Date();
            date.setDate(date.getDate()+i);
            weekOfDates.push({
                date : date.toISOString().split('T')[0],
                isAvailable : true,
            });
        }

        setDates(weekOfDates);

        let cachedRef = ref.current;
        const observer = new IntersectionObserver(
            ([e]) => {
                setIsSticky(e.intersectionRatio < 1);
            },
            {
                threshold: [1]
            }
        );

        async function getBranches() {
            const branches = await api.getBranches();
                setBranches(branches);
                setBranches((prevBranches: any[]) => prevBranches.filter((branch) => branch.id !== 54)); // remove JYG-RR
    
                cachedRef = ref.current;
    
                if (cachedRef) {
                    observer.observe(cachedRef);
                }
        }
        getBranches();

        async function getAvailableBranches() {

            const availableBranches = await api.getBranchAvailablity();
            const availableBranchesByDate = new Map<string, Array<number>>();
            for (let branch of availableBranches) {
                let availableBranches = availableBranchesByDate.get(branch.start_date) || [];
                availableBranches.push(branch.store_id);
                availableBranchesByDate.set(branch.start_date, availableBranches);
            }

            setBranchAvailability(availableBranchesByDate);
            disableUnavailableBranches();
        }
        getAvailableBranches();

        return () => {
            if (cachedRef) {
                observer.unobserve(cachedRef);
            }
        };
    }, []);

    useEffect(() => {

        disableUnavailableBranches();
        
    }, [date]);

    useEffect(() => {

        async function getEvents(branchId: string) {
            const events = await api.getEvents(branchId);
            setEvents(events);

            const datesCopy = [...dates];

            for (let d of datesCopy) {
                d.isAvailable = false;
            }
        
            for (let ev of events) {
                let eventDateStr = ev.startTime.split(" ")[0];
                for (let d of datesCopy) {
                    if (d.date === eventDateStr) {
                        d.isAvailable = true;
                    }
                }
            }
            setDates(datesCopy);
        }

        if (branchId) {
            getEvents(branchId);
        }
    }, [branchId, branches]);

    useEffect(() => {
        if (event) {
            if (currentStep < 1) {
                setCurrentStep((state) => (state += 1));
            }

            if (email && name && phone) {
                if (currentStep < 2) {
                    setCurrentStep((state) => (state += 1));
                }

                if (privacy) {
                    if (currentStep < 3) {
                        setCurrentStep((state) => (state += 1));
                    }
                }
            }
        }

        if (event && email && name && phone && privacy) {
            setIsEnabled(true);
        } else {
            setIsEnabled(false);
        }
    }, [currentStep, email, event, name, phone, privacy]);

    const createTrial = async () => {

        const payload = {
            familyName: name.split(' ')[0],
            firstName: name.split(' ')[1] || "",
            eventId: fullEvent.eventId,
            eventSessionId: fullEvent.eventSessionId,
            eventType: fullEvent.eventType,
            userId: email,
            telephone: phone,
            eMagazine: newsletter,
            comments: comment,
        };

        const response = await api.createOfflineTrial(payload);

        if (response.statusCode === 0) {
            navigate({
                pathname: '/success',
                search: createSearchParams({
                    fullName: name,
                    id: response.statusMessage,
                }).toString(),
            });
        }

        setMessage(response.statusMessage);
    };

    return (
        <>

            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com"></link>
            <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap" rel="stylesheet"></link>

            {showDialog && (
                <Dialog hide={() => setShowDialog(false)}>
                    <StyledDialogContent>
                        <StyledFormHeaderContainer>
                        <p className="form-header">予約内容をご確認ください</p>
                        </StyledFormHeaderContainer>

                        {message && <h2>{message}</h2>}

                        <table>
                            <tbody>
                                <tr>
                                    <td className="property-name">メール</td>
                                    <td className="property-delim">：</td>
                                    <td className="property-value">{email}</td>
                                </tr>
                                <tr>
                                    <td className="property-name">予約日</td>
                                    <td className="property-delim">：</td>
                                    <td className="property-value">{formatDate(fullEvent?.startTime?.split(" ")[0])}</td>
                                </tr>
                                <tr>
                                    <td className="property-name">予約時間</td>
                                    <td className="property-delim">：</td>
                                    <td className="property-value">{formatTime(fullEvent.startTime.split(" ")[1])}</td>
                                </tr>
                                <tr>
                                    <td className="property-name">店舗名</td>
                                    <td className="property-delim">：</td>
                                    <td className="property-value">{fullEvent.storeName}</td>
                                </tr>
                            </tbody>
                        </table>

                        <StyledButton
                            onClick={() => createTrial()}
                            className="dialog-button"
                        >
                            無料体験を予約する
                        </StyledButton>
                    </StyledDialogContent>
                </Dialog>
            )}

            <Page>
            <StyledHeader>
                    <StyledLogoContainer>
                        <img src="/logo.png" alt="LanCulロゴ" height="40px" />
                    </StyledLogoContainer>
                </StyledHeader>

                <StyledContent>
                    <StyledTitleContainer>
                        <h1>体験予約申し込み</h1>
                        <span>RESERVE</span>
                    </StyledTitleContainer>

                    <img src="/form_banner.png" alt="Info banner" className="form-banner" />

                    <FormStepProgress
                        steps={steps}
                        currentStep={currentStep}
                        isSticky={isSticky}
                        ref={ref}
                    />

                    <StyledEncryptionReminder>
                        ※ 暗号化通信で送信されます。
                    </StyledEncryptionReminder>

                    <StyledFormHeaderContainer>
                        <p className="form-header">お客様情報</p>

                        <span className="required-field">
                        全て<span>必須</span>項目です。
                        </span>
                    </StyledFormHeaderContainer>

                    <StyledForm
                        onSubmit={(e) => {
                            e.preventDefault();
                            setShowDialog(true);
                            setMessage("");
                        }}
                    >
                        {/* STEP 1 */}

                        <div className="input-container">
                            <label htmlFor="date">予約日時</label>

                            <select
                                name="date"
                                id="date"
                                defaultValue="disabled"
                                onChange={(e) => setDate(e.target.value)}
                                style={{ color: date ? "#333" : "#b3b3b3" }}
                                required
                            >
                                <option value="disabled" disabled>
                                    選択してください
                                </option>

                                {dates &&
                                    dates.map((date : DateOption) => (
                                        <option
                                            key={date.date}
                                            value={date.date}
                                            className={!date.isAvailable ? "disabled-option" : ""}
                                            disabled={!date.isAvailable}
                                        >
                                            {formatDate(date.date)}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="input-container">
                            <label htmlFor="store">予約店舗</label>

                            <select
                                name="store"
                                id="store"
                                defaultValue="disabled"
                                onChange={(e) => setBranchId(e.target.value)}
                                style={{ color: branchId ? "#333" : "#b3b3b3" }}
                                required
                            >
                                {/*
                                    Note that setting "selected" here produces a warning:
                                        "Warning: Use the `defaultValue` or `value` props on <select> instead of setting `selected` on <option>."
                                    but it is unavoidable if we want to re-select the disabled placeholder value.

                                    see: https://stackoverflow.com/questions/44786669/warning-use-the-defaultvalue-or-value-props-on-select-instead-of-setting
                                    "In this case unfortunately you will have to use both defaultValue and value violating React a bit. This is because react by semantics does not allow setting a disabled value as active."
                                */
                                }
                                <option
                                    value="disabled"
                                    disabled>
                                    先に予約日時を選択してください
                                </option>
                                {branches && branches.length > 0 ?
                                    (
                                        branches.map(
                                            (branch: any) => (branch.available || !date) && (
                                                <option
                                                    key={branch.id}
                                                    value={branch.id}
                                                    // disabled={typeof branch.available !== 'undefined' && !branch.available}
                                                >
                                                        {branch.name}
                                                </option>
                                            )
                                        )
                                    ) : (
                                        <option value="" id="branches-loading-placeholder" disabled>
                                            しばらくお待ちください･･･
                                        </option>
                                    )
                                }
                                {branches && branches.length > 0 &&
                                    (
                                        branches.map((branch: any) => !branch.available && date && (
                                            <option
                                                key={branch.id}
                                                value={branch.id}
                                                className="disabled-option"
                                                disabled={true}
                                                // disabled={typeof branch.available !== 'undefined' && !branch.available}
                                            >
                                                    {branch.name}
                                            </option>
                                        ))
                                    )
                                }
                            </select>
                        </div>

                        <StyledEventSelect>
                        {events && date ? (
                            events.filter((ev: any) => ev.startTime.split(" ")[0] === date).length === 0 ? (
                                <span className="event-warning">
                                    選択された日時・店舗にご案内できる英会話セッション（無料体験）はありません。別の日時や店舗をお試しください。
                                </span>
                            ) : (
                                events.map((ev: any) =>
                                ev.startTime.split(" ")[0] === date ? (
                                    <div
                                    key={`${ev.eventId}-${ev.eventSessionId}`}
                                    className="event-container"
                                    onClick={() => {
                                        setEvent(
                                            `${ev.eventId}-${ev.eventSessionId}-${ev.eventType}`
                                        );
                                        setFullEvent({
                                            ...ev,
                                        });
                                    }}
                                    >
                                        <div className="event-info">
                                            <input
                                            type="radio"
                                            value={event}
                                            checked={
                                                event ===
                                                `${ev.eventId}-${ev.eventSessionId}-${ev.eventType}`
                                            }
                                            readOnly
                                            />
                                            <div className="event-date">
                                                <span>{formatDate(ev.startTime.split(" ")[0])}</span>
                                                <span>{formatTime(ev.startTime.split(" ")[1])}</span>
                                            </div>

                                            <div className="event-mate">
                                                <StyledMateImage src={ev.mateImageLink} />
                                                <span>{ev.mateName}</span>
                                            </div>
                                        </div>

                                        <div className="event-tags">
                                            {ev.ticketCount > 0 &&
                                            ev.ticketCount < ev.ticketLimit && (
                                                <span className="event-recommended">オススメ</span>
                                            )}
                                            {ev.beginnerFlag === 1 && (
                                            <img
                                                src="/beginners-icon.png"
                                                alt="beginner"
                                                width="20px"
                                                height="25px"
                                            />
                                            )}
                                        </div>
                                    </div>
                                ) : null
                            ))
                        ) : (
                            <span className="event-warning">
                                予約店舗・日時を選択すると体験予約できるセッション一覧が表示されます。
                            </span>
                        )}
                        </StyledEventSelect>

                        {/* STEP 2 */}
                        <div className="input-container">
                            <label htmlFor="email">メールアドレス</label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="example@example.com"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <StyledEncryptionReminder>
                                ※
                                正しく届かない場合がありますので、「@lancul.com」からのメールを受信許可に設定してください。
                            </StyledEncryptionReminder>
                        </div>

                        <div className="input-container">
                            <label htmlFor="name">お名前</label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="お名前"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>

                        <div className="input-container">
                            <label htmlFor="phone">
                                電話番号 <span>＊数字のみ</span>
                            </label>

                            <input
                                type="tel"
                                name="phone"
                                id="phone"
                                placeholder="0312345678"
                                pattern="[0-9]{10,11}"
                                maxLength={11}
                                required
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>

                        <div className="input-container">
                            <label htmlFor="comment">
                                英会話のお悩みや不明点などありましたらご相談ください。{" "}
                            </label>

                            <textarea
                                name="comment"
                                id="comment"
                                cols={30}
                                rows={10}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            ></textarea>
                        </div>

                        <div className="input-container checkbox-container">
                            <input
                                type="checkbox"
                                name="newsletter"
                                id="newsletter"
                                checked={newsletter}
                                onChange={(e) => setNewsletter(e.target.checked)}
                            />

                            <div>
                                <label htmlFor="newsletter">
                                LanCulからの誕生日に届く特典やお知らせメールを受け取る
                                </label>

                                <StyledEncryptionReminder>
                                ※ いつでも設定でオフに変更できます
                                </StyledEncryptionReminder>
                            </div>
                        </div>

                        <div className="input-container checkbox-container">
                            <input
                                type="checkbox"
                                name="privacy"
                                id="privacy"
                                required
                                checked={privacy}
                                onChange={(e) => setPrivacy(e.target.checked)}
                            />

                            <div>
                                <label htmlFor="privacy">
                                <a
                                    href="https://lancul.com/terms"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    利用規約
                                </a>
                                に同意する
                                </label>
                            </div>
                        </div>

                        <StyledButton type="submit" disabled={!isEnabled}>
                            無料体験を予約する
                        </StyledButton>
                    </StyledForm>

                    <StyledCompanyInfo>
                        <h3>お問い合わせ</h3>

                        <div>
                            <img src="/icon_faq.png" />
                            <a
                                href="https://faq.lancul.com/knowledge"
                                target="_blank"
                                rel="noreferrer"
                            > 
                                    よくあるご質問
                            </a>
                        </div>
                    </StyledCompanyInfo>
                </StyledContent>
            </Page>

            <StyledFooter>
                <p>SINCE 2013 © LanCul Co., Ltd. All Rights Reserved.</p>
            </StyledFooter>

            <VersionDisplay />
        </>
    );
}

export default App;
